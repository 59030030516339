<template>
  <v-container fluid>
    <fe-swisstex v-if="$store.state.emp.cod_empresa == 381"/>
    <fe-conab v-if="$store.state.emp.cod_empresa == 383"/>
    <fe-unique v-if="$store.state.emp.cod_empresa == 380"/>
    <fe-blessing v-if="$store.state.emp.cod_empresa == 372"/>    
    <fe-ib-marker v-if="$store.state.emp.cod_empresa == 337"/>
    <fe-txool v-if="$store.state.emp.cod_empresa == 445"/>
  </v-container>
</template>

<script>
import feSwisstex from "../Facturacion/Swisstex/FeSwisstex.vue";
import feConab from "../Facturacion/Conab/FeConab.vue";
import feUnique from "../Facturacion/Unique/FeUnique.vue";
import feBlessing from "../Facturacion/Blessing/FeBlessing.vue";
import feIbMarker from "../Facturacion/IBMaker/FeIBMarker.vue"
import FeTxool from "./Txool/FeTxool.vue";

export default {
  data: () => ({
    //
  }),
  components: {
    feSwisstex,
    feConab,
    feUnique,
    feBlessing,
    feIbMarker,
    FeTxool
  },
};
</script>
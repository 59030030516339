<template>
  <v-container fluid>
    <v-row>
      <v-col cols="2">
        <v-text-field
          v-model="fecha_desde"
          label="Desde"
          outlined
          dense
          hide-details
          type="date"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          v-model="fecha_hasta"
          label="Hasta"
          outlined
          dense
          hide-details
          type="date"
        ></v-text-field>
      </v-col>
      <v-col cols="auto">
        <v-btn color="primary" @click="descargarFacturasJson()"
          >Formato JSON</v-btn
        >
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      timeout="-1"
      color="blue"
      multi-line
      elevation="24"
      outlined
      top
      right
    >
      <v-icon color="orange">{{ snackbar_icon }}</v-icon>
      {{ snackbar_text }}

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon>fa-solid fa-xmark</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      fecha_desde: "",
      fecha_hasta: "",
      //otros
      snackbar: false,
      snackbar_text: "",
      snackbar_icon: "fa-regular fa-lightbulb",
      obj_fac: [],
    };
  },
  methods: {
    async descargarFacturasJson() {
      this.obj_fac = await axios.post("api/Facturas/ExportarDocumentos", {
        id_empresa: this.$store.state.emp.id_empresa,
        desde: this.fecha_desde,
        hasta: this.fecha_hasta,
      });
      if (this.obj_fac.length == 0) {
        return;
      }
    },
  },
};
</script>